/** @format */

export var HowTileSlickSettings = {
  infinite: false,
  centerMode: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipeToSlide: true,
  autoplay: false,
  dots: true,
  arrows: false,
  draggable: true,
  responsive: [{
    breakpoint: 769,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }]
};
export var initialState = {
  firstName: '',
  lastName: '',
  memberId: '',
  contactNumber: '',
  mobilePhoneNumber: '',
  email: '',
  postcode: '',
  unformattedDOB: '',
  DOB: '',
  country: 'Australia',
  formSubmit: false,
  submitSuccess: false,
  loading: false,
  error: false,
  buttonError: false,
  checked: '',
  active: false,
  verifySuccess: '',
  rejection: '',
  action: '',
  useDefault: true,
  ignoreStatusCheck: true,
  qffLocked: false
};
var isPresent = function isPresent(val) {
  return {
    presence: {
      allowEmpty: false,
      message: "^Please enter your ".concat(val)
    }
  };
};
var isOptional = function isOptional(val) {
  return {
    presence: {
      allowEmpty: true,
      message: "^Please enter a valid ".concat(val)
    }
  };
};
var isMobile = function isMobile(val) {
  return {
    presence: {
      allowEmpty: false,
      message: "^Please enter your ".concat(val)
    },
    numericality: {
      onlyInteger: true,
      message: "^Please enter a valid ".concat(val)
    },
    format: {
      pattern: /^04\d{8}$/,
      message: '^Please enter a valid mobile starts with 04 and 10 digits'
    }
  };
};
var isPostcode = function isPostcode(val) {
  return {
    presence: {
      allowEmpty: false,
      message: "^Please enter your ".concat(val)
    },
    numericality: {
      onlyInteger: true,
      message: "^Please enter a valid ".concat(val)
    },
    length: {
      is: 4,
      message: "^".concat(val, " must be a four digits")
    }
  };
};
var isQFF = function isQFF(val) {
  return {
    numericality: {
      onlyInteger: true,
      '>': 0,
      '<=': 2147483647,
      message: "^Please enter a valid ".concat(val)
    }
  };
};
var isEmail = function isEmail(val) {
  return {
    email: {
      message: "^Please enter your valid ".concat(val)
    }
  };
};
var isChecked = function isChecked(val) {
  return {
    presence: {
      message: "^Please read and accept ".concat(val)
    },
    inclusion: {
      within: [true],
      message: "^Please read and accept ".concat(val)
    }
  };
};
export var constraints = {
  firstName: isPresent('first name'),
  lastName: isPresent('last name'),
  memberId: isQFF('Qantas Frequent Flyer number'),
  mobilePhoneNumber: isMobile('mobile number'),
  postcode: isPostcode('Postcode'),
  email: isEmail('email address'),
  DOB: isOptional('date of birth'),
  checked: isChecked('Terms and Conditions')
};