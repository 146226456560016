var __jsx = React.createElement;
/** @format */

import React, { Fragment } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import LazyLoad from 'react-lazy-load';
import { isOfferValid, pointsCalculator, merchantCardCallout } from "../../../constants/app";
import styled, { css } from 'styled-components';
import { colours, fontSizes, customFonts, layoutSizes } from "../../../constants/css";
import getLink from "../../../utils/getLink";
import { directEarnCurrentOffer, directEarnOffers, pointsTermCalculator, merchantPriority } from "../../Offer/offerUtils";
import CardButton from "../../Favourites/CardButton";
import { CardCallout } from "../../_global";
var StyledCard = styled.div.withConfig({
  displayName: "Card__StyledCard",
  componentId: "sc-1tdj59g-0"
})(["position:relative;display:flex;flex-direction:column;justify-content:space-evenly;background-color:", ";border-radius:2.2px;box-shadow:0 2px 3px 0 rgba(0,0,0,0.2),0 11px 10px 0 rgba(0,0,0,0.05);max-width:180px;margin-bottom:1rem;transition:all 0.15s linear;&:hover{box-shadow:0 2px 3px 0 rgba(45,45,45,0.02),rgba(45,45,45,0.02) 0px 2px 2px,rgba(49,49,49,0.05) 0px 4px 4px,rgba(42,42,42,0.05) 0px 8px 8px,rgba(32,32,32,0.05) 0px 16px 16px,rgba(49,49,49,0.05) 0px 13px 19px,rgba(35,35,35,0.05) 0px 10px 40px;transform:translate(0,-4px);}@media only screen and (min-width:", "){max-width:209px;height:194px;}", " ", " a{position:absolute;top:0;left:0;right:0;bottom:0;text-decoration:none;z-index:1;}"], function (props) {
  return props.isSpooky ? colours.qantasIceWhite : colours.qantasWhite;
}, layoutSizes.desktopWidth, function (_ref) {
  var isMobile = _ref.isMobile;
  return isMobile && css(["min-width:100%;box-shadow:unset;border-radius:0;height:4.5rem;margin:0;overflow:hidden;&::after{content:'';border-bottom:0.5px solid ", ";position:absolute;display:block;left:4.25rem;width:100%;bottom:0px;}&:hover{box-shadow:none;transform:none;}"], colours.qantasBorderLightGrey);
}, function (props) {
  return props.isSpooky && "\n    button {\n      background-color: ".concat(colours.qantasIceWhite, ";\n    }\n  ");
});
var CardTitle = styled.h2.withConfig({
  displayName: "Card__CardTitle",
  componentId: "sc-1tdj59g-1"
})(["font-family:", ";font-size:", ";color:", ";font-weight:normal;font-style:normal;font-stretch:normal;line-height:normal;letter-spacing:normal;max-height:1em;text-overflow:ellipsis;white-space:nowrap;display:block;margin:0;font-size:", ";@media only screen and (min-width:", "){font-size:", ";}", ""], customFonts.ciutadellaMed, fontSizes.small, colours.qantasCharcoal, fontSizes.small, layoutSizes.desktopWidth, fontSizes.base, function (_ref2) {
  var isMobile = _ref2.isMobile;
  return isMobile && css(["font-size:", ";font-weight:500;"], fontSizes.large);
});
var ImageWrapper = styled.div.withConfig({
  displayName: "Card__ImageWrapper",
  componentId: "sc-1tdj59g-2"
})(["text-align:center;width:150px;margin:1rem auto 0;"]);
var CardImageWrapper = styled.div.withConfig({
  displayName: "Card__CardImageWrapper",
  componentId: "sc-1tdj59g-3"
})(["height:130px;position:relative;", ""], function (_ref3) {
  var isMobile = _ref3.isMobile;
  return isMobile && css(["width:3rem;height:3rem;position:absolute;top:0.875rem;left:1rem;"]);
});
var CardBody = styled.div.withConfig({
  displayName: "Card__CardBody",
  componentId: "sc-1tdj59g-4"
})(["padding:0 0.5rem 5px 0.5rem;margin-top:5px;p{margin:0;font-size:", ";@media only screen and (min-width:", "){font-size:", ";}}", ""], fontSizes.small, layoutSizes.desktopWidth, fontSizes.base, function (_ref4) {
  var isMobile = _ref4.isMobile;
  return isMobile && css(["position:absolute;width:13rem;left:4.75rem;p{font-size:", ";margin-top:5px;color:", ";}"], fontSizes.base, colours.qantasPlaceHolderLightGrey);
});
var renderGhost = function renderGhost(merchant, isMobile) {
  return __jsx(StyledCard, {
    key: merchant.merchantId,
    isSpooky: true,
    isMobile: isMobile
  }, __jsx(CardButton, {
    merchantId: merchant.merchantId,
    isMobile: isMobile
  }), __jsx(CardBody, {
    isMobile: isMobile
  }, __jsx(CardTitle, {
    itemProp: "name",
    isMobile: isMobile
  }, merchant.merchantName), __jsx("p", null, "is currently not available at Qantas Shopping.")));
};
var getPointsTerm = function getPointsTerm(merchant, directOffer) {
  if (!merchant.merchantIdOLM) {
    if (merchant.merchantIdDirect) {
      var _ref5 = directOffer || {},
        perValue = _ref5.perValue;
      return pointsTermCalculator('DIRECT', perValue, null);
    }
    return 'Card Offers Store';
  }
  return pointsTermCalculator('MALL', 1, null);
};
var displayedOffer = function displayedOffer(merchant, directOffer) {
  if (merchant.merchantIdOLM) {
    return merchant.rebate && merchant.rebate.rebate_user;
  }
  return directOffer && directOffer.points;
};
var renderPointsOffer = function renderPointsOffer(priority, pointsShown, pointTerms, directOffer) {
  if (priority === 'MALL' || pointsShown) {
    return __jsx("p", null, pointsShown, pointsShown > 1 ? ' points ' : ' point ', pointTerms);
  }
  if (priority === 'DIRECT') {
    return __jsx("p", null, directOffer && directOffer.bespokeOffer);
  }
  return __jsx("p", null, "Card Offers Store");
};
// eslint-disable-next-line react/prop-types
var LazyLoadWrapper = function LazyLoadWrapper(_ref6) {
  var children = _ref6.children,
    isMobile = _ref6.isMobile;
  return __jsx(Fragment, null, isMobile ? __jsx(LazyLoad, {
    height: 72
  }, children) : __jsx(LazyLoad, {
    height: 150,
    offset: '0px 0px 500px 0px'
  }, children));
};
var Card = function Card(_ref7) {
  var merchant = _ref7.merchant,
    onClick = _ref7.onClick,
    skipMerchantPage = _ref7.skipMerchantPage,
    bonusRibbons = _ref7.bonusRibbons,
    isCircleHeart = _ref7.isCircleHeart,
    isMobile = _ref7.isMobile;
  var _ref8 = merchant.rebate || {},
    start_date = _ref8.start_date,
    end_date = _ref8.end_date,
    rebate_user = _ref8.rebate_user;
  var validOffer = isOfferValid(start_date, end_date);
  var directOffer;
  if (merchant.merchantIdDirect) {
    var offer = directEarnOffers(merchant.directOffers);
    directOffer = directEarnCurrentOffer(offer);
  }
  var pointTerms = getPointsTerm(merchant, directOffer);
  var calloutCopy = merchant.is_elevated ? pointsCalculator(parseInt(rebate_user, 10), parseInt(merchant.was_rebate.rebate_user, 10)) : merchantCardCallout(merchant);
  var linkObj = getLink(merchant, skipMerchantPage);
  var pointsShown = displayedOffer(merchant, directOffer);
  var priority = merchantPriority(merchant);
  if (merchant.isSpooky) {
    return renderGhost(merchant, isMobile);
  }
  var id = "".concat(!validOffer || !merchant.is_elevated ? 'not' : '', "bonus_").concat(merchant.merchantId.substr(0, 1));
  return __jsx(StyledCard, {
    key: merchant.merchantId,
    id: id,
    isMobile: isMobile
  }, __jsx(Link, {
    href: linkObj.linkHref,
    as: linkObj.linkAs,
    passHref: true
  }, __jsx("a", {
    onClick: onClick,
    role: "button"
  }, "\xA0")), validOffer && merchant.is_elevated && __jsx(CardCallout, {
    bonusRibbons: bonusRibbons,
    callType: calloutCopy.split(' ')[0],
    isRightTop: isMobile
  }, calloutCopy), __jsx(CardButton, {
    merchantId: merchant.merchantId,
    isCircleHeart: isCircleHeart,
    isMobile: isMobile
  }), ' ', !merchant.is_elevated && calloutCopy && __jsx(CardCallout, {
    bonusRibbons: bonusRibbons,
    callType: calloutCopy.split(' ')[0],
    isRightTop: isMobile
  }, calloutCopy), __jsx(LazyLoadWrapper, {
    isMobile: isMobile
  }, __jsx(ImageWrapper, null, merchant.isNew && __jsx(CardCallout, {
    bonusRibbons: bonusRibbons,
    callType: "NEW",
    isRightTop: isMobile
  }, "NEW STORE"), __jsx(CardImageWrapper, {
    isMobile: isMobile
  }, __jsx(Image, {
    src: merchant.merchantLogoSquare,
    alt: "".concat(merchant.merchantName, "'s online shopping"),
    layout: "fill",
    objectFit: "contain"
  })))), __jsx(CardBody, {
    isMobile: isMobile
  }, __jsx(CardTitle, {
    itemProp: "name",
    isMobile: isMobile
  }, merchant.merchantName), !merchant.isVisible && linkObj.linkAs === '/card-offers' ? __jsx("p", null, "Card Offers Store") : renderPointsOffer(priority, pointsShown, pointTerms, directOffer)));
};
Card.defaultProps = {
  merchant: {},
  onClick: function onClick() {
    // intentionally blank
  },
  skipMerchantPage: false,
  bonusRibbons: [],
  isCircleHeart: false
};
export default Card;