var getLink = function getLink(merchant, skip) {
  var linkObj = {
    linkHref: '',
    linkAs: ''
  };
  if (skip && (!merchant.merchantIdOLM || merchant.merchantIdOLM && merchant.merchantIdMCO && !merchant.isVisible)) {
    linkObj.linkHref = '/card-offers';
    linkObj.linkAs = '/card-offers';
  } else {
    linkObj.linkHref = "/merchant?slug=".concat(merchant.merchantId.replace(/\+/g, '%2B'));
    linkObj.linkAs = "/".concat(merchant.merchantId);
  }
  return linkObj;
};
export default getLink;