import { colours, customFonts, fontSizes, layoutSizes, responsiveQuery } from "../../../constants/css";
import styled from 'styled-components';
import { Container } from "../../_global";
var arrow = "/static/link-arrow.svg";
var bubble = "/static/offers/blue-bubble.svg";
export var Banner = styled.img.withConfig({
  displayName: "Style__Banner",
  componentId: "sc-j0oscb-0"
})(["height:auto;width:100%;margin-bottom:-6px;"]);
export var BannerCopy = styled.div.withConfig({
  displayName: "Style__BannerCopy",
  componentId: "sc-j0oscb-1"
})(["text-align:center;margin:auto;@media only screen and (min-width:", "){width:64%;}a{text-decoration:none;color:", ";}p{margin:0;text-align:center;font-family:", ";}"], layoutSizes.desktopWidth, colours.qantasCharcoal, customFonts.ciutadellaMed);
export var BannerTitle = styled.p.withConfig({
  displayName: "Style__BannerTitle",
  componentId: "sc-j0oscb-2"
})(["font-family:", ";color:", ";text-transform:uppercase;font-size:", ";font-weight:500;font-style:normal;font-stretch:normal;line-height:1.25;letter-spacing:normal;text-align:center;margin-bottom:14px !important;img{@media only screen{position:relative;top:1.5rem;}}"], customFonts.ciutadellaMed, colours.qantasRed, fontSizes.xxl);
export var JoinLink = styled.p.attrs({
  name: function name(props) {
    return props.hasName || null;
  }
}).withConfig({
  displayName: "Style__JoinLink",
  componentId: "sc-j0oscb-3"
})(["color:", ";font-size:", ";font-family:", ";padding-top:0.3rem;span{padding-right:0.5rem;}"], colours.qantasRed, fontSizes.large, customFonts.ciutadellaMed);
export var MerchantBanner = styled.div.withConfig({
  displayName: "Style__MerchantBanner",
  componentId: "sc-j0oscb-4"
})(["padding:1rem 0;> div:first-child{> a{color:#888888;line-height:2.31;letter-spacing:1.14px;}}"]);
export var MerchantContainer = styled(Container).withConfig({
  displayName: "Style__MerchantContainer",
  componentId: "sc-j0oscb-5"
})(["@media only screen and (min-width:", "){width:100%;}"], layoutSizes.desktopWidth);
export var MerchantCouponContainer = styled(Container).withConfig({
  displayName: "Style__MerchantCouponContainer",
  componentId: "sc-j0oscb-6"
})(["padding:2rem 0;text-align:center;img,h2{display:inline;margin-top:0;}img{margin-right:0.5rem;vertical-align:sub;}"]);
export var MerchantNameWrapper = styled.div.withConfig({
  displayName: "Style__MerchantNameWrapper",
  componentId: "sc-j0oscb-7"
})(["display:flex;justify-content:space-between;align-items:center;"]);
export var MerchantWrapper = styled.div.withConfig({
  displayName: "Style__MerchantWrapper",
  componentId: "sc-j0oscb-8"
})(["transform:translateY(-2rem);@media only screen and (min-width:", "){transform:translateY(-195px);}"], layoutSizes.desktopWidth);
export var MerchantLogo = styled.div.withConfig({
  displayName: "Style__MerchantLogo",
  componentId: "sc-j0oscb-9"
})(["top:50%;transform:translateY(-50%);box-shadow:0 2px 4px 0 rgba(0,0,0,0.2);border:solid 5px ", ";height:64px;width:64px;background-color:", ";@media only screen and (min-width:", "){margin:0;top:100%;transform:translateY(-100%);box-shadow:none;border-top-right-radius:4px;border-top-left-radius:4px;height:195px;width:195px;}img{max-width:100%;}"], colours.qantasWhite, colours.qantasWhite, layoutSizes.desktopWidth);
export var MerchantMain = styled.section.withConfig({
  displayName: "Style__MerchantMain",
  componentId: "sc-j0oscb-10"
})(["margin-top:1rem;padding-top:1rem;box-shadow:inset 0 4px 4px -4px #00000034;@media only screen and (min-width:", "){margin-top:0;}h2{font-family:", ";font-weight:normal;font-style:normal;font-stretch:normal;line-height:normal;letter-spacing:normal;font-size:1.625rem;margin-bottom:0.3rem;color:", ";}", ""], layoutSizes.desktopWidth, customFonts.ciutadellaMed, function (_ref) {
  var theme = _ref.theme;
  return theme.colours.qantasCharcoal;
}, function (props) {
  return props.isGrey && "\n    background-color: ".concat(colours.qantasIceWhite, ";\n    padding-bottom: 2rem;\n    text-align: center;\n  ");
});
export var MerchantTitle = styled.h1.withConfig({
  displayName: "Style__MerchantTitle",
  componentId: "sc-j0oscb-11"
})(["font-size:", ";font-weight:normal;font-style:normal;font-stretch:normal;line-height:1.06;letter-spacing:normal;margin:0;@media only screen and (min-width:", "){font-size:2.125rem;}"], fontSizes.xxxl, layoutSizes.desktopWidth);
export var AboutWrapper = styled.div.withConfig({
  displayName: "Style__AboutWrapper",
  componentId: "sc-j0oscb-12"
})(["@media only screen and (min-width:", "){display:flex;max-width:1170px;margin:auto;padding:0 15px;}"], layoutSizes.desktopWidth);
export var MerchantAboutTitle = styled.p.withConfig({
  displayName: "Style__MerchantAboutTitle",
  componentId: "sc-j0oscb-13"
})(["font-size:", ";font-weight:normal;font-style:normal;font-stretch:normal;line-height:normal;letter-spacing:normal;font-family:", ";"], fontSizes.xxl, customFonts.ciutadellaMed);
export var MerchantDescription = styled.div.withConfig({
  displayName: "Style__MerchantDescription",
  componentId: "sc-j0oscb-14"
})(["margin-top:0;padding:0 1rem;font-size:", ";line-height:25px;"], fontSizes.large);
export var MerchantCouponWrapper = styled.div.withConfig({
  displayName: "Style__MerchantCouponWrapper",
  componentId: "sc-j0oscb-15"
})(["margin-bottom:2rem;margin-top:1rem;"]);
export var RightArrow = styled.i.withConfig({
  displayName: "Style__RightArrow",
  componentId: "sc-j0oscb-16"
})(["display:inline-block;background:url(", ");background-repeat:no-repeat;width:1rem;height:7px;"], arrow);
export var FAQWrapper = styled(Container).withConfig({
  displayName: "Style__FAQWrapper",
  componentId: "sc-j0oscb-17"
})(["> div{margin-top:0 !important;> div{padding:0;}}"]);
export var OtherStores = styled(Container).withConfig({
  displayName: "Style__OtherStores",
  componentId: "sc-j0oscb-18"
})(["padding:0.5rem 0 1rem 1rem;margin-bottom:2rem;> p{font-family:", ";font-size:", ";margin-top:0;}"], customFonts.ciutadellaMed, fontSizes.xl);
export var StyledCardWrapper = styled.div.withConfig({
  displayName: "Style__StyledCardWrapper",
  componentId: "sc-j0oscb-19"
})(["position:relative;overflow:hidden;padding-top:0.5rem;.slick-disabled{display:none !important;}.slick-list{padding-left:1px;overflow:initial;}img{margin:auto;}"]);
export var AboutImage = styled.div.withConfig({
  displayName: "Style__AboutImage",
  componentId: "sc-j0oscb-20"
})(["background-image:url('", "');background-repeat:no-repeat;background-size:cover;background-position:top center;width:100%;min-height:227px;@media only screen and (min-width:", "){min-width:50%;}"], function (props) {
  return props.src;
}, layoutSizes.desktopWidth);
export var OfferBox = styled.div.withConfig({
  displayName: "Style__OfferBox",
  componentId: "sc-j0oscb-21"
})(["display:flex;max-width:594px;border-radius:4px;box-shadow:0 1px 1px 0 #00000019;border:solid 1px #e8e8e8;background-color:", ";margin:auto;padding:1rem;background-image:url('", "');background-repeat:no-repeat;background-position:bottom right;position:relative;@media only screen and (min-width:", "){padding:3rem 3rem 3rem 0;height:292px;}aside{min-width:30%;display:flex;justify-content:space-between;flex-direction:column;text-align:center;p{margin-bottom:0;}svg{width:120px;@media only screen and (min-width:", "){width:163px;}}}"], colours.qantasWhite, bubble, layoutSizes.desktopWidth, layoutSizes.desktopWidth);
export var InfoButton = styled.button.withConfig({
  displayName: "Style__InfoButton",
  componentId: "sc-j0oscb-22"
})(["color:", ";background:", ";padding:0;border:none;cursor:pointer;text-decoration:underline;font-family:", ";font-size:", ";margin:0;"], colours.qantasGrey, colours.qantasWhite, customFonts.ciutadellaReg, fontSizes.base);
export var MobileInfo = styled.div.withConfig({
  displayName: "Style__MobileInfo",
  componentId: "sc-j0oscb-23"
})(["text-align:left;margin-top:0.5rem;"]);
export var MerchantPanelWrapper = styled(Container).withConfig({
  displayName: "Style__MerchantPanelWrapper",
  componentId: "sc-j0oscb-24"
})(["display:flex;justify-content:center;align-items:center;flex-direction:column;margin-top:24px;@media only screen and (min-width:", "){padding:0;margin-top:32px;}"], layoutSizes.desktopWidth);
export var MerchantPanelHeader = styled.h2.withConfig({
  displayName: "Style__MerchantPanelHeader",
  componentId: "sc-j0oscb-25"
})(["max-width:303px;text-align:left;margin:0;@media only screen and (min-width:", "){margin:0;max-width:100%;}"], layoutSizes.desktopWidth);
export var MerchantCardContainer = styled.div.withConfig({
  displayName: "Style__MerchantCardContainer",
  componentId: "sc-j0oscb-26"
})(["display:flex;justify-content:", ";align-self:center;padding-left:", ";overflow-x:", ";scroll-behavior:smooth;position:relative;.base-merchant-card-wrapper:last-child:not(:only-child){padding-right:15px;}@media only screen and (min-width:", "){justify-content:", ";padding:0 15px;}@media only screen and (min-width:1839px){justify-content:center;}"], function (props) {
  return props.showPebble ? 'flex-start' : 'center';
}, function (props) {
  return props.showPebble ? '15px' : 0;
}, function (props) {
  return props.showPebble ? 'auto' : 'unset';
}, layoutSizes.containerWideWidth, function (props) {
  return props.totalMerchantCards > 2 ? 'flex-start' : 'center';
});
export var PebbleContainer = styled.div.withConfig({
  displayName: "Style__PebbleContainer",
  componentId: "sc-j0oscb-27"
})(["position:relative;z-index:2;text-align:right;top:55px;left:-21px;height:70px;@media only screen and ", "{left:-19px;width:0;}"], responsiveQuery.tabletAndDesktop);
export var MerchantCardWrapper = styled.div.withConfig({
  displayName: "Style__MerchantCardWrapper",
  componentId: "sc-j0oscb-28"
})(["display:flex;max-width:310px;@media only screen and ", " and ", "{max-width:610px;}@media only screen and ", "{max-width:100%;margin-right:30px;}"], responsiveQuery.desktop, responsiveQuery.mobileAndTablet, responsiveQuery.tabletAndDesktop);
export var PointsCalloutHeadline = styled.p.withConfig({
  displayName: "Style__PointsCalloutHeadline",
  componentId: "sc-j0oscb-29"
})(["text-align:left;white-space:nowrap;font-family:", ";font-size:", ";margin:0 0 12px 15px;color:", ";@media ", "{text-align:center;font-size:1.625rem;margin:0 0 24px 0;}"], function (_ref2) {
  var theme = _ref2.theme;
  return theme.customFonts.ciutadellaMed;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.headingSizes.h3;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colours.qantasCharcoal;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.responsiveQuery.desktop;
});