import _taggedTemplateLiteral from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
/** @format */

import gql from 'graphql-tag';
export var joinQuery = {
  joinFormQuery: gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query checkDirectMemberJoinQuery($merchantId: String, $joinBody: directMemberBody) {\n      checkDirectMemberJoin(merchantId: $merchantId, joinBody: $joinBody) {\n        succeeded\n        action\n        rejection\n        loyaltyMemberId\n        message\n      }\n    }\n  "])))
};
export var statusQuery = {
  edmStatusQuery: gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query checkDirectMemberStatusQuery(\n      $merchantId: String\n      $memberId: String\n    ) {\n      checkDirectMemberStatus(\n        merchantId: $merchantId\n        memberId: $memberId\n      ) {\n        isAlreadyLinked\n        memberInfo {\n          firstName\n          dateOfBirth\n          email\n          memberId\n        }\n      }\n    }\n  "])))
};
export var validationQuery = {
  memberValidQuery: gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    query checkValidateMemberQuery($memberId: String, $surname: String) {\n      checkMemberDetailsValid(memberId: $memberId, surname: $surname) {\n        status\n        message\n      }\n    }\n  "])))
};