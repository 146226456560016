export var similarSettings = {
  infinite: false,
  centerMode: false,
  slidesToShow: 4.5,
  slidesToScroll: 1,
  swipeToSlide: true,
  autoplay: false,
  dots: false,
  arrows: false,
  draggable: true,
  responsive: [{
    breakpoint: 960,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1
    }
  }, {
    breakpoint: 768,
    settings: {
      slidesToShow: 3.5,
      slidesToScroll: 1
    }
  }, {
    breakpoint: 580,
    settings: {
      slidesToShow: 1.7,
      slidesToScroll: 1
    }
  }]
};
export var CardSlickSettings = {
  infinite: false,
  centerMode: false,
  slidesToShow: 2.5,
  slidesToScroll: 1,
  swipeToSlide: true,
  autoplay: false,
  dots: false,
  arrows: false,
  draggable: true,
  responsive: [{
    breakpoint: 960,
    settings: {
      slidesToShow: 2.4,
      slidesToScroll: 1
    }
  }, {
    breakpoint: 768,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1
    }
  }, {
    breakpoint: 580,
    settings: {
      slidesToShow: 1.5,
      slidesToScroll: 1
    }
  }]
};