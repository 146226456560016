import PropTypes from 'prop-types';
export var merchantProps = {
  merchant: PropTypes.shape({
    merchantId: PropTypes.string,
    merchantIdMCO: PropTypes.string,
    merchantIdDirect: PropTypes.string,
    merchantName: PropTypes.string,
    merchantDescriptionLong: PropTypes.string,
    merchantHero: PropTypes.string,
    merchantLogo: PropTypes.string,
    merchantLogoSquare: PropTypes.string,
    metaDescription: PropTypes.string,
    metaTags: PropTypes.string,
    is_elevated: PropTypes.bool,
    is_new: PropTypes.bool,
    has_coupon: PropTypes.bool,
    exclusions: PropTypes.string,
    coupons: PropTypes.array,
    rebate: PropTypes.shape({
      rebate_term: PropTypes.string,
      rebate_user: PropTypes.number,
      amount_type: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string
    }),
    was_rebate: PropTypes.shape({
      rebate_term: PropTypes.string,
      rebate_user: PropTypes.number,
      amount_type: PropTypes.string,
      start_date: PropTypes.string
    }),
    details: PropTypes.array,
    merchantStatus: PropTypes.string,
    promoted: PropTypes.shape({
      prepend_special_terms: PropTypes.string
    }),
    directOffers: PropTypes.array,
    directContent: PropTypes.shape({
      directPanelTitle: PropTypes.string,
      directPanelCopy: PropTypes.string,
      directPanelUrl: PropTypes.string
    }),
    merchantFaq: PropTypes.array,
    howItWorksBlock: PropTypes.arrayOf(PropTypes.shape({
      howTitle: PropTypes.string,
      howLogo: PropTypes.string,
      howCopy: PropTypes.string
    })),
    calloutBlock: PropTypes.arrayOf(PropTypes.shape({
      callToAction: PropTypes.string,
      calloutImage: PropTypes.string,
      description: PropTypes.string,
      linkUrl: PropTypes.string,
      title: PropTypes.string
    }))
  }),
  loading: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  faqData: PropTypes.shape({
    faqSections: PropTypes.arrayOf(PropTypes.shape),
    loading: PropTypes.bool
  }),
  similarMerchants: PropTypes.arrayOf(PropTypes.shape),
  discrete_days: PropTypes.number,
  merchantImportantInfo: PropTypes.string
};
export var merchantPropsDefault = {
  merchant: {},
  loading: true,
  error: {},
  favourites: {},
  faqData: {
    faqSections: [],
    loading: true
  },
  similarMerchants: []
};